/** Custom Scrollbar */
/* https://css-tricks.com/scrollbars-on-hover/ */
:root {
  --scroll-bar-width: 9px;
}

/* width */
.custom-scrollbar::-webkit-scrollbar {
  width: var(--scroll-bar-width);
  margin-right: 15px;
  /* background-color: red; */
}

/* Track */
.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f162;
  background-color: white;
  margin-top: 5px;
  margin-bottom: 5px;
}

/* Handle */
.custom-scrollbar::-webkit-scrollbar-thumb {
  background: rgba(136, 136, 136, 0.692);
  border-radius: 50px;
}

/* Handle on hover */
.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: rgba(85, 85, 85, 0.719);
}

.custom-scrollbar {
  /* padding: 17px 0 17px 17px; */
  /* mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent 0px, black 0px); */
  mask-size: 100% 20000px;
  mask-position: left bottom;
  -webkit-mask-image: linear-gradient(to top, transparent, black),
    linear-gradient(to left, transparent var(--scroll-bar-width), black 0px);
  -webkit-mask-size: 100% 20000px;
  -webkit-mask-position: left bottom;
  transition: mask-position 0.3s, -webkit-mask-position 0.3s;
}

.custom-scrollbar:hover {
  -webkit-mask-position: left top;
}

/** End **/
