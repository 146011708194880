body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  height: 100%;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* html, body, #app, #app>div {
  height: 100%;
} */

#mapid {
  height: 180px;
}

/* fix grid layout */
/*  */
.MuiGrid-container {
  margin: 0 !important;
  width: 100% !important;
}

.main {
  height: 100% !important;
  /* background-color: green; */
}

/* .MuiToolbar-regular {
  min-height: 44px !important;
  height: 44px !important;
}

.makeStyles-drawerHeader-7 {
  min-height: 44px !important;
  height: 44px !important;
} */

/* INPUT TYPE SEARCH CURSOR ON CLEAR BUTTON */
input[type='search']::-webkit-search-decoration:hover,
input[type='search']::-webkit-search-cancel-button:hover {
  cursor: pointer;
}
